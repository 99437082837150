import React, { useState } from 'react';
import { categories } from '../../utils/data';
import { Link, Input, Box, InputGroup, InputRightElement, Text, IconButton } from '@chakra-ui/react';
import { SearchIcon, CloseIcon } from '@chakra-ui/icons';

export const normalizeString = (str: string) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
};

const SearchComponent = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredQuestions, setFilteredQuestions] = useState([]);
    const [hasSearched, setHasSearched] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setSearchTerm(value);
      setHasSearched(value.length >= 3);

      if (value.length >= 3) {
          const normalizedValue = normalizeString(value);
          const filtered = categories.flatMap(category =>
              category.questions.filter(question =>
                  normalizeString(question.title).includes(normalizedValue) ||
                  normalizeString(question.answer).includes(normalizedValue)
              ).map(question => ({
                  ...question,
                  categoryTitle: category.title
              }))
          );
          setFilteredQuestions(filtered);
      } else {
          setFilteredQuestions([]);
      }
    };

  const clearSearch = () => {
    setSearchTerm('');
    setFilteredQuestions([]);
  };

  return (
    <Box w={{ base: '100%', sm: '100%', md: '60%', lg: '50%' }} mx="auto">
        <InputGroup>
            <Input 
                type="text"
                value={searchTerm}
                onChange={handleChange}
                placeholder="Búsqueda..."
                borderColor="gray.200"
                focusBorderColor="gray.200"
            />
            <InputRightElement>
              <SearchIcon />
              {searchTerm && (
                <IconButton 
                    icon={<CloseIcon />} 
                    onClick={clearSearch} 
                    variant="link"
                    size="xs"
                />
              )}
            </InputRightElement>
        </InputGroup>
        {filteredQuestions.length >= 0 && (
            <Box 
                maxH="135px"
                overflowY="auto"
                textAlign={"left"}
                border="1px"
                borderColor="gray.200"
                borderTop="none"
                boxShadow="md"
                css={{
                  '&::-webkit-scrollbar': {
                      width: '6px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                      background: '#CBD5E0',
                      borderRadius: '10px',
                  },
                  '&::-webkit-scrollbar-track': {
                      background: '#EDF2F7',
                  },
              }}
            >
                {filteredQuestions.map((question, index) => (
                    <Text key={index}
                    px={3}
                    mt={'10px'}
                    fontSize="14px" 
                    _hover={{ bg: 'gray.100', cursor: 'pointer' }}
                    borderBottom={{ base: '1px', md: 'none' }}
                    borderColor="gray.100"
                    >
                        <Link href={`/faqs/${question.slug}`}>
                        {question.categoryTitle} - {question.title}
                        </Link>
                    </Text>
                ))}
                {hasSearched && filteredQuestions.length === 0 && (
                    <Text
                        px={3}
                        mt={'10px'}
                        fontSize="14px" 
                        _hover={{ bg: 'gray.100', cursor: 'pointer' }}
                        borderBottom={{ base: '1px', md: 'none' }}
                        borderColor="gray.100"
                    >
                        No se encontraron resultados.
                    </Text>
                )}
            </Box>
        )}
    </Box>
  );
};

export default SearchComponent;
